<template>
  <div
    v-if="!isElementHiddenOnLocale($i18n.locale)"
    v-editable="blok"
    :style="[
      maxWidth,
      paddings,
      fullWidthStyle,
      fullHeightStyle,
      horizontalAlignment,
      paddingValue,
      borderColor,
    ]"
    :class="[
      itemAlignment,
      position,
      alignToBottomClass,
      {
        'box-wrapper--separator': blok.vertical_separator,
      },
    ]"
    class="box-wrapper"
  >
    <StoryblokComponent
      v-for="childBlok in blok.body"
      :key="childBlok._uid"
      :blok="childBlok"
    />
  </div>
</template>

<script lang="ts" setup>
import type { SbBoxWrapperStoryblok } from '@/types'

interface Props {
  blok: SbBoxWrapperStoryblok
}
const props = defineProps<Props>()

const maxWidth = computed(() => {
  const defaultWidth = '100%'

  const widthValues = {
    '--max-width-tablet': props.blok.width?.tablet
      ? `${props.blok.width.tablet}px`
      : defaultWidth,
    '--max-width-mobile': props.blok.width?.mobile
      ? `${props.blok.width.mobile}px`
      : defaultWidth,
    '--max-width-desktop': props.blok.width?.desktop
      ? `${props.blok.width.desktop}px`
      : defaultWidth,
  }

  if (props.blok.block_width !== '' && props.blok.block_width !== '0') {
    return {
      ...widthValues,
      '--max-width-desktop': `${props.blok.block_width}px`,
    }
  }

  return widthValues
})
const isElementHiddenOnLocale = (locale: string) => {
  return props.blok.hide_element === `hide_${locale}`
}

const position = computed(() => {
  return props.blok.position ? `box--${props.blok.position}` : 'box--left'
})

const horizontalAlignment = computed(() => {
  if (props.blok.item_alignment) {
    return ''
  }

  return {
    '--horizontal-alignment-desktop': props.blok.horizontal_alignment?.desktop,
    '--horizontal-alignment-mobile': props.blok.horizontal_alignment?.mobile,
    '--horizontal-alignment-tablet': props.blok.horizontal_alignment?.tablet,
  }
})

const itemAlignment = computed(() => {
  return props.blok.item_alignment
    ? `wrapper wrapper--${props.blok.item_alignment}`
    : 'wrapper'
})

const alignToBottomClass = computed(() => {
  return props.blok.options?.includes('align_to_bottom')
    ? 'wrapper--bottom'
    : null
})

const fullWidthStyle = computed(() => {
  return props.blok.options?.includes('full_width')
    ? {
        width: '100%',
      }
    : {
        width: 'auto',
      }
})

const fullHeightStyle = computed(() => {
  return props.blok.options?.includes('full_height')
    ? {
        height: '100%',
      }
    : {
        height: 'auto',
      }
})

const paddingValue = computed(() => {
  return {
    '--padding-desktop': props.blok.padding?.desktop
      ? `${props.blok.padding.desktop}px`
      : '0',
    '--padding-tablet': props.blok.padding?.tablet
      ? `${props.blok.padding.tablet}px`
      : '0',
    '--padding-mobile': props.blok.padding?.mobile
      ? `${props.blok.padding.mobile}px`
      : '0',
  }
})

const paddings = computed(() => {
  const values = {
    '--padding-mobile': `var(--${paddingValue.value['--padding-mobile']})`,
  }

  if (!props.blok.padding_placement) {
    return values
  }

  const optionsArray = props.blok.padding_placement.map((p) => p.toLowerCase())

  const reducedPaddings = optionsArray.reduce((o: object, key: string) => {
    return {
      ...o,
      [`--padding-${key}`]: 'var(--padding-responsive)',
    }
  }, {})

  return {
    ...reducedPaddings,
    values,
  }
})

const borderColor = computed(() => {
  if (props.blok.vertical_separator) {
    return {
      borderColor: props.blok.separator_color?.value,
    }
  }
})
</script>

<style lang="scss" scoped>
.box-wrapper {
  --max-width-desktop: 100%;
  --max-width-tablet: 100%;
  --max-width-mobile: 100%;
  --horizontal-alignment-desktop: stretch;
  --horizontal-alignment-tablet: stretch;
  --horizontal-alignment-mobile: stretch;
  --padding-desktop: 0;
  --padding-tablet: 0;
  --padding-mobile: 0;
  --padding-responsive: 0;

  align-items: var(--horizontal-alignment-mobile);
  max-width: var(--max-width-mobile);
  padding: var(--padding-mobile);

  &--separator {
    border-bottom: 2px solid var(--gray-200);
    padding-bottom: 48px !important;
    @media (min-width: $breakpoint-lg) {
      border-bottom: none;
      border-right: 2px solid var(--gray-200);
      padding-bottom: 0 !important;
    }
  }

  @media (min-width: $breakpoint-md) {
    --padding-responsive: var(--padding-tablet);

    align-items: var(--horizontal-alignment-tablet);
    max-width: var(--max-width-tablet);
    padding: var(--padding-top, 0) var(--padding-right, 0)
      var(--padding-bottom, 0) var(--padding-left, 0);
  }

  @media (min-width: $breakpoint-lg) {
    --padding-responsive: var(--padding-desktop);

    align-items: var(--horizontal-alignment-desktop);
    max-width: var(--max-width-desktop);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;

  &--center {
    align-items: center;
  }

  &--left {
    align-items: flex-start;
  }

  &--right {
    align-items: flex-end;
  }

  &--bottom {
    justify-content: flex-end;
  }
}

.box {
  margin-left: 0;
  margin-right: 0;

  &--center {
    @media (min-width: $breakpoint-md) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--left {
    @media (min-width: $breakpoint-md) {
      margin-right: auto;
    }
  }

  &--right {
    @media (min-width: $breakpoint-md) {
      margin-left: auto;
    }
  }
}
</style>
